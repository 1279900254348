.new-checkout .text-copy {
    border: none;
    background-color: #f5f5f5;
    padding: 0;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    outline: 0;
}

.new-checkout .card-checkout-info {
    width: 100%;
    padding: 2%;
}

.new-checkout .card-checkout-info-inner {
    p {
        font-weight: 500;
        font-size: 16px;
        &:first-child {
            font-size: 17px;
            font-weight: 700;
        }

        &:nth-child(5) {
            font-weight: 700;
        }
        span {
            color: #D81E5B;
            font-weight: 600;
        }
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 1%;
}


.new-checkout .card-checkout-info-inner h1 {
    font-size: 25px;
}

.new-checkout .card-checkout-info-inner h2 {
    font-size: 20px;
}


.new-checkout .card-body-pix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-checkout .container-method-payment {
    display: flex; 
    flex: 1; 
    justify-content: space-between; 
    margin-top: 1%;
    padding: 2%;
}
  

@media only screen and (max-width: 768px) {
    .new-checkout .card-checkout-info {
        width: 100%;
    }
}


.new-checkout .button-card-pix-disabled {
    opacity: 0.3;
    cursor: unset;
}

.new-checkout .pix-disabled {
    opacity: 0.3;
    cursor: unset;
}

.new-checkout .card-pix {
    background: #F6F6F6;
    border-radius: 4px;
}

@media only screen and (min-width: 1368px) {
    .new-checkout .card-pix {
    }
}

.new-checkout .text-copy-disabled {
    opacity: 0.3;
    cursor: unset;
}

.new-checkout .card-pix-start {
    display: flex;
    padding: 5% 0;
}

.new-checkout .new-checkout-card {
    display: flex;
    justify-content: center; 
    flex-grow:1;
    padding: 5% 0;
}


.new-checkout .new-checkout-card h1 {
    font-size: 22px;
    @media(max-width: 728px) {
        font-size: 14px;
        text-align: justify;
    }
}

.new-checkout .new-checkout-card svg {
    font-size: 60px;
}

.new-checkout {
    .btn-generate-pix {
        padding: 18px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 49px;
        border-radius: 4px;
        height: 45px !important;
        width: 279px;
        border: none;
        transition: filter 0.2;
        &:hover {
            filter:brightness(0.8);
            height: 45px;
        }
        @media (max-width:768px) {
            width: 235px;
        }
    }
}

