.h1 {
  color: $white-color;
  font-size: 6vw;
  line-height: 0.9em;
  margin-bottom: 0.5em;
}
.header-title {
  color: $dark-grey;
  font-size: 20px;
}
