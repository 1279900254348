$max-sm: 767px;
$max-md: 991px;
$max-xl: 1023px;

$layout-space: 20px;

$header-height: 60px;
$footer-height: 60px;

//colors

$white-color: white;
$dark-grey: #222222;

$main-color: #eeeeee;
$main-text-color: $dark-grey;
$sidebar-bg-color: $white-color;
$border-layout-color: #eeeeee;
$notification-count-bg: #fa9f42;
$list-title-color: #666666;
$grey-cccccc: #cccccc;

$button-action-color: #12c735;

$lessons-list-bg-color: $sidebar-bg-color;
$lessons-list-border-color: $border-layout-color;
$lessons-list-title-color: $notification-count-bg;

$footer-social-icon-color: #070736;
