@mixin sm-max {
  @media (max-width: #{$max-sm}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$max-sm + 1}) {
    @content;
  }
}
@mixin md-max {
  @media (max-width: #{$max-md}) {
    @content;
  }
}
@mixin xl-max {
  @media (max-width: #{$max-xl}) {
    @content;
  }
}