.new-checkout {
    .container-card-course-error {
        background: #AAA;
        padding: 0 0 10px 0;
        border-radius: 13px 13px 0 0;
    }
    
    .total-free {
        color: #2EC76B;
    }
}

.main-img-card {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
