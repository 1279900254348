.ant-layout-header {
  background-color: transparent;
  align-items: center;
  color: $dark-grey;
  display: flex;
  height: $header-height;
  line-height: 1;
  padding: 15px;
  .anticon {
    margin-right: 10px;
  }
  .header-title {
    display: flex;
    align-items: center;
    font-weight: normal;
  }
  .ant-breadcrumb {
    font-size: 16px;
    color: $dark-grey;
  }
  .ant-breadcrumb > span:last-child {
    font-weight: bold;
  }

  .ant-breadcrumb > span:first-child {
    font-weight: normal;
  }
}
