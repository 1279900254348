.container-therms-of-service {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    .ant-modal-content {
        border-radius: 10px;
        height: 222px;
        width: 470px;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        .container-therms-of-service-body{
            border-radius: 10px;
            padding-top: 10%;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-around;
            align-items: center;
            h1{
                text-align: center;
                font-size: 19px;
                font-weight: 600;
                color: #000A66;
                padding: 0 10px;
            }

            .container-therms-of-service-buttons {
                width: 90%;
                
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                margin-top: 25px;

                button{
                    text-align: center;
                    box-sizing: border-box;
                    border-radius: 4px;
                    width: 176px;
                    height: 45px;
                    margin-top: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: filter 0.2;
                    &:hover {
                        filter:brightness(0.9);
                    }

                    @media only screen and (max-width: 768px) {
                        width: 150px;
                    }
                }

                .button-warning{
                    color: white;
                    background-color: #F89C3A;
                    border: none;
                }
            }
        }
    }    
}