

.btn-col-content {
  padding: 0 !important;
}

.new-checkout {
  font-family: 'Montserrat';
  & {
    input {
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.btn-button {
  background-color: #d81e5b !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #99999900 !important;
      border-top-color: #99999900 !important;
      border-right-color: #99999900 !important;
      border-right-width: 1px;
      border-bottom-color: #99999900 !important;
      border-left-color: #99999900 !important;
  border-radius: 4px;
  line-height: 35px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 44px !important;
  line-height: 45px !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #fff0 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #fff0 !important;
}

.input-form input,
.ant-select-selector {
  background: #f6f6f6 !important;
  border-color: #ffffff03;
}

.ant-select-selector {
  align-items: center;
  height: 45px !important;
  border-color: rgba(255, 255, 255, 0);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: 2px solid #D81E5B !important;
  color: #D81E5B !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 4px #D81E5B !important;
  color: #D81E5B !important;
}

.radio-group-item-checbox .ant-radio-checked .ant-radio-inner {
  border-color: #D81E5B !important;
}

.radio-group-item-checbox .ant-radio-inner::after {
  background-color: #D81E5B !important;
}

.back-grd {
  background-color: white !important;
}

.btn-steps {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.current-step {
  text-decoration: none;
  position: relative;
}

.current-step:after {
  content: '';
  background: #d81e5b;
  border: 2px solid #d81e5b;
  bottom: -9px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: rotate(180deg);
  transition: 0.5s;
  width: 100%;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
}

.disable-button {
  display: none !important;
}

.disable-tab {
  border: 4px solid #7a7979fa;
  border-left: 1px solid #f6f6f600;
  border-right: 1px solid #f6f6f600;
  border-top: 1px solid #f6f6f600;
  color: #cccccc;
  margin-bottom: -9px;
  opacity: 0.5;
  pointer-events: none;
  width: 120px;
  padding-bottom: 5px;
}

.input-form {
  line-height: 17px;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
}

.step {
  margin-left: 13px;
}

.step-bar {
  color: #d81e5b;
  width: 120px;
}

.step-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.content-l {
  padding: 80px;
  margin: 0 auto;
}

@media only screen and (max-width: 375px) {
  .content-l {
    padding: 20px;
  }
}

/*SEUS DADOS PAGE*/
.title-steps {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000a66;
  margin: 58px 0px 27px;
}

.title-form {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000a66;
  margin-left: 14px;
  margin-bottom: 13px;
  width: 200px !important;

  &.margin-cupons{
    margin-top: 20px;
    margin-left: 0;
  }
}

.input-form {
  height: 45px !important;
  border-radius: 4px;
}

.content-hero {
  padding: auto 50px !important;
}

.btn-aplicar {
  height: 100% important;
}

/* .max-container-row-form {
  max-width: 615px;
} */

.btn-next {
  display: flex !important;
  justify-content: flex-end;
}

.btn-default-orange {
  background-color: #f89c3a !important;
  color: #ffffff !important;
  width: 176px !important;
  height: 45px !important;
  border-radius: 4px !important;
  cursor: pointer;

  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  gap: 25px !important;
  margin-top: 54px;
}

.btn-default-white {
  border: 2px solid #000a66 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  color: #000a66 !important;
  width: 176px !important;
  height: 45px !important;
  border-radius: 4px !important;
  cursor: pointer;

  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap:30px !important;
  /* margin-top: 54px; */
}

.container-card-course {
  width: 325px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  position: relative;
  padding-bottom: 20px;
}

.course-name {
  width: 327px;
  margin: 24px 0 24px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000;

  p {
    text-wrap: wrap;
    width: calc(100% - 50px);
    word-wrap: break-word;
  }
}

.divider {
  border: 1px dashed #CCCCCC;
  transform: rotate(180deg) !important;
  width: 100%;
  display: block;
  margin: 30px 0 30px;
}

.price-card {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #BBBBBB;
}

.row-price-card {
  padding-left: 24px;
  padding-right: 24px;
}

.t-end {
  text-align: end;
}

.total-price-card {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000A66;
}

.row-total-price-card {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
}
.total-price-card-number {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 33px;
  color: #000A66;
}

.img-content {
  display: flex;
  align-items: center;
  height: 100%;
  background: none !important;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.text-description-card-price {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.read-or-hide {
  color: #F89C3A;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;  
  cursor: pointer;
}

.read-more {
  margin-top: 10px;
}

.span-box {
  width: 90%;
  height: 25px;
  display: block;
  position: absolute;
  margin-top: -17px;
  background: #fff;
  opacity: 1;
  background: linear-gradient( 0grad, #fff, #fafafa6b);
}

.cupom-form {
  width: 200px !important;

  &.cupons-mobile-input{
    width: 100% !important;
  }
}

.container {
  max-width: 1400px !important;
}

.radio-group-form {
  display: flex !important;
  align-items: flex-end !important;
  width: 100% !important;
}

.radio-gropup-item {
  background: #f6f6f6 !important;
  display: flex !important;
  align-items: center !important;
}

.radio-group-item-checbox {
  margin-left: 22px !important;
}

.cred-card {
  display: flex;
  gap: 17px;
}

.item-cred-card {
  gap: 6px;
  display: flex;
}

.mainLoginInput{
  height: 40px;
  padding: 0px;
  font-size: 30px;
  margin: 5px 0;
}

.placeholder-svg {
  font-family:Arial, FontAwesome;
}

.title-card-pix {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  width: 219px;
  padding: 31px 0 61px;
}

.title-card-pix-qr {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  width: 219px;
  padding: 31px 0;
}

.button-card-pix {
  width: 199px;
  height: 45px;
  color: white;
  background: #D81E5B;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: filter 0.2;
  &:hover {
    filter:brightness(0.9)
  }
}


.time-cod-pix {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #D81E5B;
  width: 100%;
  margin: 60px 0 10px;
}

.time-cod-pix-num {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #D81E5B;
}

.title-steps-last {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #2EC76B;
  margin: 44px 0 30px;
  display: flex;
  gap: 5px;
}

.title-description-last-step {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  width: 411px;
  margin-bottom: 20px;
}

.description-last-step {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 411px;
  margin: 30px 0 30px;
}

.description-last-step-strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  width: 411px;
}
.contact-whats-app {
  margin: 30px 0 5px;
}
.whats-app-number {
  color: #D81E5B !important;
}

.start-course {
  background: #F89C3A;
  color: white;
  margin-top: 40px;
  padding: 22px;
  gap: 30px;
  Width: 216px;
  Height: 45px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.container-card-confirmed {
  height: 94px;
  background: #F89C3A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #FFFDED;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: -8px;
}

.title-failed-payment {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #D81E5B;
  margin-bottom: 45px;
  gap: 5px;
}

.description-failed-payment {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 36px;
}

.text-failed-payment {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  width: 339px;
}

.contact-failed-payment {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #D81E5B;
  margin: 5px 0 36px;
}

.btn-support-help {
  width: 279px;
  height: 45px;
  background: #F89C3A;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-weight: 600;
  font-size: 14px;
  border: none;

  cursor: pointer;
}
.failed-payment {
  background-color: black !important;
  width: 383px;
  height: 854px;
  position: absolute;
  z-index: 999;
  opacity: 0.7;
  border-radius: 10px;
}

.completed {
  color: #000A66;
  width: 100%;
  border-bottom: 4px solid #000A66;
  padding-bottom: 5px;

  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}


.btn-send-message{ 
  width: 279px;
  height: 45px;
  left: 117px;
  top: 946px;
  background: #F89C3A;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  left: 2px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.error-code {
  margin: 80px 0px;
  background-color: #F6F6F6;
  padding: 50px 50px;
  width: 600px;
  white-space: pre;
  white-space: pre-line;
  white-space: pre-wrap;
}

@media only screen and (min-width: 320px) {
  .content-l {
    padding: 30px;
  }
  .max-container-row-form {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .btn-default-orange {
    width: 315px !important;
    gap: 90px !important;
    margin-top: 0px !important;
  }
  .btn-default-white {
    width: 315px !important;
    gap: 90px !important;
  }
  .cupom-form {
    width: 235px;
  }

  .button-card-pix {
    margin: 27px 0 -29px;
  }

  .card-pix {
    width: 318px !important;
    height: 183px !important;
  }

  .button-radio-xs-lg {
    width: 95px !important;
    height: 56px !important;
    background: #F6F6F6 !important;
    border-radius: 4px !important;
    border-color: #fff0 !important;
    color: #DDDDDD !important;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .title-radio-xs-lg {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000533;
    margin: 10px 0 20px;
    max-width: 150px;
    }

  .title-radio-xs-lg-pix {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000533;
    margin: 10px 0 20px;
    margin-left: -25px;
    max-width: 90px;
  }

  .card-price-xs-lg {
    width: 315px;
    height: 139px;
    background: #FFFFFF;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    margin: 0 auto;
  }

  .img-conatiner-card {
    width: 151px !important;
    height: auto !important;
  }

  .title-card-price-xs-lg {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 20px 0 14px 10px;
  }
  .course-card-price-xs-lg{
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    max-width: 138px;
    margin: 0 0 0 10px;

  }
  .number-card-price-xs-lg {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000A66;
    justify-content: flex-end;
    margin: 0px 20px 0px 0px;
  }
  .step-text {
    font-weight: 400;
    font-size: 9px !important;
    line-height: 10px !important;
    width: 70px;
  }


  .title-steps {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  .title-steps-last {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center; 
    color: #2EC76B;
    margin: 44px 0 46px;
    display: flex;
    gap: 5px;
  }
  .title-description-last-step{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    width: 315px;
  }
  .description-last-step {
    display: contents;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    width: 315px;
    margin: 10px 0 10px;
  }
  .description-last-step-strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    width: 315px;
    margin: 25px 0 25px;
  }

  .btn-default-whats {
    width: 194px;
    height: 45px;
    font-weight: 600;
    background: #F89C3A;
    border-radius: 4px;
    color: white;
    border-color: #fff0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 90px 60px 70px;
    cursor: pointer;
  }
  .title-failed-payment {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .text-failed-payment {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    width: 313px;
  }
  .btn-support-help {
    margin: 70px 10px; 
  }
  .completed {
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    width: 70px;
  }
  .description-failed-payment {
    width: 313px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
  .completed {
    width: 78px !important;
  }
  .current-step::after {
    width: 78px !important;
  }
  .disable-tab {
    width: 78px !important;
  }
  .btn-next {
    display: flex !important;
    justify-content: center !important;
    left: 0 !important;
    margin: 0 0 77px;
  }
  .btn-back {
    display: flex !important;
    justify-content: center !important;
    left: -20px;
    margin-top: 0px !important;
  }
  .col-pix {
    display: flex !important;
    justify-content: center;
  }
  .btn-next-one {
    display: flex !important;
    justify-content: flex-end;
  }  
  .row-failed {
    display: flex !important;
    justify-content: center;
  }
  .failed-payment-xs-md {
    background-color: black !important;
    width: 315px;
    height: 139px;
    position: absolute;
    z-index: 999;
    opacity: 0.7;
    border-radius: 10px;
  }
  .col-cred-mobile,
  .col-debit-mobile,
  .col-pix-mobile  {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
.col-cred-mobile {
  display: grid;
  justify-content: flex-start;
}

.col-debit-mobile {
  display: grid;
  justify-content: center;
}

.col-pix-mobile {
  display: grid;
  justify-content: flex-end;
}
.btn-back {
  left: 0;
}

}


@media only screen and (min-width: 768px) {

  .content-l {
    padding: 78px !important;
    margin: 0 auto !important; 
    justify-content: center;
    max-width: 820px;
  }

  .card-price-xs-lg {
    width: 596px !important;
    height: 236px !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);
    border-radius: 10px !important;
    margin: 0px 20px !important;
  }

  .img-conatiner-card {
    width: 100% !important;
    height: auto !important;
  }
  .title-card-price-xs-lg {
    width: 229px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    color: #000000 !important;
  }
  .course-card-price-xs-lg {
    max-width: 289px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    color: #000000 !important;
  }
  .number-card-price-xs-lg {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    text-align: right !important;
    color: #000A66 !important;
    margin: 19px 15px 8px 0px !important;
  }

  .title-steps {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 28px !important;
    line-height: 34px !important;
    display: flex !important;
    align-items: center !important;
    color: #000A66 !important;
    margin-left: 10px !important;
  }

  .title-failed-payment {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 28px !important;
    line-height: 34px !important;
    display: flex !important;
    align-items: center !important;
    color: #D81E5B !important;
  }

  .description-failed-payment {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #000000 !important;
  }

  .text-failed-payment {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #000000 !important;
  }

  .btn-support-help {
    margin: 10px 0 271px !important;
  }
  .description-last-step {
    width: 411px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #000000 !important;
  }
  /* */
  .step-bar {
    width: 100% !important;
  }
  .current-step::after {
    width: 100% !important;
  }
  .completed {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    width: 100% !important;
    color: #000A66;
  }
  .disable-tab {
    width: 100% !important;
  }
  .btn-default-orange {
    width: 176px !important;
    height: 45px !important;

    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    gap: 25px !important;
    left: 40px;
  }
  .btn-default-white {
    width: 176px !important;
    height: 45px !important;

    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 30px !important;
    left: -50px !important;
  }
  .row-md {
    align-items: flex-end !important;
  }
  .btn-next {
    left: 10px !important;
  }
  .btn-next-one {
    display: flex !important;
    justify-content: flex-end !important;
    left: -50px;
  }

  .step-text {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    width: 100% !important;
  }
  .card-pix {
    width: 260px !important;
    height: 295px !important;
  }
  .row-failed {
    display: flex !important;
    justify-content: flex-start;
    padding: 40px 0;
  }
  .failed-payment-xs-md {
    background-color: black !important;
    width: 596px !important;
    height: 236px !important;
    position: absolute;
    z-index: 999;
    opacity: 0.7;
    border-radius: 10px;
  }
  .disable-tab-end {
    border: 4px solid #7a7979fa;
    border-left: 1px solid #f6f6f600;
    border-right: 1px solid #f6f6f600;
    border-top: 1px solid #f6f6f600;
    color: #cccccc;
    margin-bottom: -9px;
    opacity: 0.5;
    pointer-events: none;
    width: 120px;
    padding-bottom: 5px;
  }

  .buttons-back-next {
    margin-top: 43px;
    display: flex !important;
    align-items: initial !important;
  }
  .max-container-row-form {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

}

@media only screen and (min-width: 1200px) {
  .content-l {
    max-width: 1336px;
  }
  .img-conatiner-card {
    width: 390px !important;
    height: 391px !important;
    border-radius: 10px 10px 0 0 !important;
  }
  .btn-back {
    margin-top: -77px;
  }

}

.btn-next-one {
  margin-top: 30px;
}

.btn-back-one {
  margin-top: 30px;
  padding-left: 75px !important;
  @media(max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-left: 2% !important;
    padding-right: 2%;
    button {
      border: 2px solid #000a66;
      box-sizing: border-box;
      border-radius: 4px;
      color: #000a66;
      width: 315px;
      height: 45px;
      border-radius: 4px;
      cursor: pointer;
  
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        position: absolute;
        left: 10px;
        bottom: 15px
      }
    }
  }
}

.container-brand-option {
  padding: 2px 0px;
  border-bottom: 0.5px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
}

.btn-content-process {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.btn-content-process > .btn-content-process-text {
  flex: 1;
}


.btn-content-process svg {
  font-size: 16px;
  color: white;
}

.cupom-container .btn-cupom {
  flex: 1; 
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100% !important;
}

.cupom-container > div:first-child{
  flex: 1;
}

.cupom-container .btn-cupom svg {
  font-size: 16px; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-checkout .new-checkout-card {
  background: #F6F6F6;
  border-radius: 4px;
}






















































/*DISPLAY FLEX*/
.d-flex {
  display: flex;
}

/*align-items*/

.a-items-c {
  align-items: center !important;
}

.a-items-e {
  align-items: flex-end !important;
}

.a-items-s {
  align-items: flex-start !important;
}

/*justify-content*/

.j-c-s {
  justify-content: start !important;
}

.j-c-c {
  justify-content: center !important;
}

.j-c-s {
  justify-content: end !important;
}


/*gap*/

.gap-25 {
  gap: 25px !important;
}

.top-indicate-area{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 110px;

  margin: 0 70px 0 60px;

  cursor: pointer;

  text-decoration: none;

  transition: 1s filter;

  &:hover{
    filter: drop-shadow(0px 11px 20px rgba(0, 0, 0, 0.22));
  }

  @media screen and (max-width: 1200px) {
    margin: 0 0 0 30px;
    flex-direction: column;
  }
}

.left-indicate-area{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 35px 45px;

  width: 100%;
  height: 110px;
  background: linear-gradient(100.12deg, #5768FF 4.37%, #414DB7 96.93%);
  border-radius: 5px 0px 0px 5px;

  gap: 22px;

  p{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 25px;

    display: flex;
    align-items: center;


    color: #FFFFFF;
  }

  h1{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 32px;

    display: flex;
    align-items: center;


    color: #FFFFFF;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: calc(100% - 30px);

    border-radius: 5px 5px 0px 0px;

    gap: 4px;
    padding: 20px;

    p {
      font-size: 0.75rem;
      line-height: 16px;
    }

    h1 {
      font-size: 1rem;
      line-height: 21px;
    }
  }
}

.right-indicate-area{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 212px;
  height: 110px;

  /* Escala de cinza/0% */

  background: #FFFFFF;
  border-radius: 0px 5px 5px 0px;

  border: 3px solid #414DB7;

  p{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 25px;

    display: flex;
    align-items: center;


    color: #5768FF;
  }

  @media screen and (max-width: 1200px) {
    border: 2px solid #414DB7;
    border-radius: 0px 0px 5px 5px;

    width: calc(100% - 30px);

    svg {
      width: 28px;
    }

    p {
      font-size: 0.75rem;
      line-height: 16px;
    }
  }
}
