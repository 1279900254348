.container-pix {
    padding: 0% 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    .container-pix-body {
        width: 415px;
        height: 288px;
        display: flex;
        flex-direction: column;
        padding: 63px 77px 62px 59px;
        border-radius: 4px;
        background-color: #F6F6F6;
        @media (max-width: 768px) {
            width: 370px;      
        } 

        label {
            color: #000A66;
            font-weight:600;
            font-size: 14px;
            margin-left: 19px;
        }
        .box-cupom {
            margin-top: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            .btn-cupom {
                flex: 1; 
                display: flex; 
                justify-content: center; 
                align-items: center;
                height: 45px;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
                
                svg {
                    font-size: 16px; 
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }              
            input {
                font-size: 14px;
                font-weight: 500;
                padding-left: 18px;
                width: 279px;
                height: 45px;
                background-color: #FFFFFF;
                border-radius: 4px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border: none;
            }
        }
        .box-contract {
            padding-left: 14px !important;
        }
    }
}